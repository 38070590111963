import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/payment-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentMethodsByCountryConfig(ctx, { countryCode }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/payment-methods', {
            params: { countryCode },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/processing/payments/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentNotifications(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/processing/payments/${id}/notifications`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/payments', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    export(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/payments/export', {
            params: queryParams,
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/vnd.ms-excel',
              Accept: 'application/vnd.ms-excel',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    fetchPaymentToBeRefunded(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/refunds/payment-to-be-refunded', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRefund(ctx, model) {
      return new Promise((resolve, reject) => {
        axios
          .post('/processing/refunds', model)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
