<template>
  <v-card>
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <payment-details :id="router.currentRoute.params.id"></payment-details>
      </v-tab-item>

      <v-tab-item>
        <payment-notifications :id="router.currentRoute.params.id"></payment-notifications>
      </v-tab-item>

      <v-tab-item>
        <payment-provider-logs :id="router.currentRoute.params.id"></payment-provider-logs>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiViewList,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import router from '@/router'
import PaymentDetails from './PaymentDetails.vue'
import PaymentNotifications from './PaymentNotifications.vue'

export default {
  components: {
    PaymentDetails,
    PaymentNotifications,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Details', icon: mdiAccountOutline },
      { title: 'Notifications', icon: mdiBellOutline },
    ]

    return {
      tab,
      tabs,
      router,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiViewList,
      },
    }
  },
}
</script>
