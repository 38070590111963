<template>
  <div>
    <v-card>
      <v-data-table
        ref="notificationsTable"
        :headers="notificationsTableColumns"
        :items="notificationsItems"
        :loading="loading"
        disable-pagination
        loading-text="Loading... Please wait"
      >
        <!-- Status -->
        <template #[`item.statusCode`]="{ item }">
          <v-chip
            small
            :color="resolveStatusVariant(item.statusCode)"
            :class="`${resolveStatusVariant(
              item.statusCode
            )}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.statusCode }}
          </v-chip>
        </template>
        <template #[`item.networkRequestBody`]="{ item }">
          <div
            class="truncate"
            style="max-width: 330px;"
          >
            {{ item.networkRequestBody }}
          </div>
        </template>

        <template #[`item.networkResponseBody`]="{ item }">
          <div
            class="truncate"
            style="max-width: 330px;"
          >
            {{ item.networkResponseBody }}
          </div>
        </template>
        <template #[`item.createdAt`]="{ item }">
          <span>{{ formatDateAndTime(item.createdAt) }}</span>
        </template>
      </v-data-table>
    </v-card>
    <v-toast ref="vtoast"></v-toast>
  </div>
</template>

<script>

import {
  formatDateAndTime,
} from '@core/utils/filter'
import {
  mdiPageFirst,
  mdiPageLast,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCubeSend,
} from '@mdi/js'
import {
  ref, onMounted, onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import VToast from '@/components/VToast.vue'
import storeModule from './storeModule'

export default {
  components: { VToast },
  props: {
    id: {
      type: String,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'processing-notifications'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const paymentId = ref(props.id)
    const loading = ref(false)
    const vtoast = ref()

    const notificationsItems = ref([])

    const notificationsTableColumns = [
      {
        text: 'CREATED ON', value: 'createdAt', sortable: false, align: 'center', width: 180,
      },
      {
        text: 'STATUS', value: 'statusCode', sortable: false, align: 'center',
      },
      {
        text: 'REPLY CODE', value: 'replyCode', sortable: false, align: 'center',
      },
      {
        text: 'REPLY DESC', value: 'replyDesc', sortable: false, align: 'center',
      },
      {
        text: 'HTTP STATUS', value: 'httpStatusCode', sortable: false, align: 'center',
      },
      {
        text: 'RESPONSE BODY', value: 'networkResponseBody', sortable: false, align: 'center', width: 200,
      },
    ]

    const fetchNotifications = () => {
      loading.value = true
      store
        .dispatch('processing-payments/fetchPaymentNotifications', {
          id: paymentId.value,
        })
        .then(response => {
          notificationsItems.value = response.data
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchNotifications()
    })

    const resolveStatusVariant = status => {
      if (status.toLowerCase() === 'pending') return 'warning'
      if (status.toLowerCase() === 'paid') return 'success'
      if (status.toLowerCase() === 'failed') return 'secondary'

      return 'primary'
    }

    return {
      paymentId,
      notificationsTableColumns,
      notificationsItems,
      loading,
      vtoast,
      resolveStatusVariant,
      formatDateAndTime,
      fetchNotifications,

      // icons
      icons: {
        mdiPageFirst,
        mdiPageLast,
        mdiChevronLeft,
        mdiChevronRight,
        mdiCubeSend,
      },
    }
  },
}
</script>

<style scoped>
.notifiedAt {
  font-size: 11px;
  padding: 5px;
}
.table-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
