<template>
  <v-navigation-drawer
    :value="isAddNewRefundSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 550 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-refund-sidebar-active', val)"
  >
    <v-dialog
      v-model="isDialogVisible"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Do you continue?
        </v-card-title>
        <v-card-text>
          A new refund will be created which will remain on Pending status
          until its processing.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            :disabled="loading"
            @click="isDialogVisible = false"
          >
            No
          </v-btn>
          <v-btn
            color="success"
            :loading="loading"
            :disabled="loading"
            @click="onAgree"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <!-- Create New Refund -->
      <div class="drawer-header d-flex align-center mb-4">
        <span
          class="font-weight-semibold text-base text--primary"
        >Create Refund: {{ refundData.paymentCode }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-refund-sidebar-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-card
            class="mx-auto px-2 mb-5"
            outlined
          >
            <v-list-item
              three-line
              :disabled="paymentFoundModel === null"
            >
              <v-list-item-content>
                <div class="text-overline mb-4">
                  REFUND DETAILS
                </div>

                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-label>
                    Amount to be refunded: <strong>{{ formatCurrency(refundData.amount) }} {{ refundData.currencyCode }}</strong>
                  </v-label>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="refundData.referenceCode"
                    outlined
                    dense
                    label="Merchant Reference"
                    placeholder="Merchant Reference"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="refundData.comments"
                    :rules="[validators.required]"
                    outlined
                    dense
                    placeholder="Comments"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Comments
                    </template>
                  </v-text-field>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
            :disabled="!valid || paymentFoundModel === null"
          >
            Submit
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetRefundData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiMagnify } from '@mdi/js'
import { ref, watch, toRefs } from '@vue/composition-api'
import {
  required,
  emailValidator,
  decimalValidator,
} from '@core/utils/validation'
import {
  formatDateAndTime,
  formatCurrency,
} from '@core/utils/filter'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewRefundSidebarActive',
    event: 'update:is-add-new-refund-sidebar-active',
  },
  props: {
    isAddNewRefundSidebarActive: {
      type: Boolean,
      required: true,
    },
    paymentCode: {
      type: String,
      required: true
    },
    shopFilterItems: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const valid = ref(false)
    const form = ref(null)
    const paymentFoundModel = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const blankRefundData = {
      paymentCode: '',
      amount: '',
      currencyCode: '',
      notificationUrl: '',
      referenceCode: '',
      comments: '',
    }

    const refundData = ref(JSON.parse(JSON.stringify(blankRefundData)))
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const resetRefundData = () => {
      paymentFoundModel.value = null
      refundData.value = JSON.parse(JSON.stringify(blankRefundData))
      resetForm()
      emit('update:is-add-new-refund-sidebar-active', false)
    }

    const onClearPaymentFoundHandler = () => {
      paymentFoundModel.value = null
      refundData.value.amount = null
      refundData.value.currencyCode = null
    }

    const onSubmit = () => {
      if (valid.value) {
        isDialogVisible.value = true
      } else {
        validate()
      }
    }

    // Refund
    const { isAddNewRefundSidebarActive, paymentCode } = toRefs(props);

    watch(isAddNewRefundSidebarActive, (newVal) => {
      if (newVal) {
        onSearchPaymentHandler()
      }
    })

    const onSearchPaymentHandler = () => {
      refundData.value.paymentCode = props.paymentCode
      
      if (refundData.value.paymentCode === '') return

      loading.value = true

      store
        .dispatch('processing-payments/fetchPaymentToBeRefunded', {
          paymentCode: refundData.value.paymentCode,
        })
        .then(response => {
          paymentFoundModel.value = response.data === '' ? null : response.data

          if (paymentFoundModel.value !== null) {
            refundData.value.amount = paymentFoundModel.value.amountToBeRefunded
            refundData.value.currencyCode = paymentFoundModel.value.currencyCode
          }
        })
        .catch(error => {
          emit('refund-error', { messages: error.response.data.errors, type: 'error' })

          return error
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onAgree = () => {
      loading.value = true

      store
        .dispatch('processing-payments/addRefund', refundData.value)
        .then(response => {
          // emit('refetch-data')
          emit('update:is-add-new-refund-sidebar-active', false)
          emit('refund-saved', response.data)
          resetRefundData()
        })
        .catch(error => {
          isDialogVisible.value = false
          emit('refund-error', { messages: error.response.data.errors, type: 'error' })

          return error
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      refundData,
      isDialogVisible,
      valid,
      loading,
      paymentFoundModel,
      resetRefundData,
      onAgree,
      onSubmit,
      onSearchPaymentHandler,
      onClearPaymentFoundHandler,
      validate,
      formatDateAndTime,
      formatCurrency,

      // validation
      validators: { required, emailValidator, decimalValidator },
      icons: {
        mdiClose,
        mdiMagnify,
      },
    }
  },
}
</script>
