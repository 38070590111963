<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-title>
      Payment
      <!-- <slot name="title"></slot> -->
      <v-spacer></v-spacer>
      <!-- color="primary"
          :disabled="!item.allowsToBeRefunded"
          @click.stop="onRefundClick( )" -->

      <div>
        <v-btn
          v-if="paymentModel.paymentMethod.channelCode === paymentMethodChannel.CREDIT_CARD"
          color="primary"
          :disabled="!paymentModel.allowsToBeRefunded"
          @click.stop="onRefundClick( )"
        >
          Refund
        </v-btn>
      </div>
    </v-card-title>

    <refund-add-new
      v-model="isAddNewRefundSidebarActive"
      :payment-code="paymentCode"
      :shop-filter-items="shopFilterItems"
      @refund-saved="onRefundSaved"
      @refund-error="onRefundError"
    ></refund-add-new>

    <v-card-text>
      <v-row class="ma-0 pa-0">
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.paymentCode"
            label="UID"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.createdAt"
            label="Created On"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="amountDetail"
            label="Amount"
            readonly
            variant="plain"
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="statusDetail"
            label="Status"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.shopName"
            label="Shop Name"
            readonly
          >
          </v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.orderId"
            label="Order ID"
            readonly
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.description"
            label="Description"
            readonly
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.notificationUrl"
            label="Notification Url"
            readonly
            variant="plain"
          ></v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.expirationTime"
            label="Expiration Time"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title>Payment Method</v-card-title>
    <v-card-text>
      <v-row class="ma-0 pa-0">
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.paymentMethod.name"
            label="Payment Method"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.paymentMethod.channelName"
            label="Payment Method Channel"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.paymentMethod.countryName"
            label="Payment Method Country"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row
        v-if="showCreditCardDetail"
        class="ma-0 pa-0"
      >
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.paymentCreditCardDetail.cardHolder"
            label="Card Holder"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.paymentCreditCardDetail.cardEmail"
            label="Card e-mail"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.paymentCreditCardDetail.applicationPan"
            label="PAN"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title>Merhant Discount Rates</v-card-title>
    <v-card-text>
      <v-row class="ma-0 pa-0">
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="merchantDiscountRateComputed.fixed"
            label="Fixed"
            readonly
          >
          </v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="merchantDiscountRateComputed.percentage"
            label="Percentage"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="merchantDiscountRateComputed.applyToCustomer"
            label="Apply To Customer"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="merchantDiscountRateComputed.minRateDiscount"
            label="Min Discount"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="merchantDiscountRateComputed.rateDiscount"
            label="Discount"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="merchantDiscountRateComputed.totalDiscount"
            label="Total Discount"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title>Customer</v-card-title>
    <v-card-text>
      <v-row class="ma-0 pa-0">
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.firstName"
            label="First Name"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.lastName"
            label="Last Name"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.address"
            label="Address"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>

        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.city"
            label="City"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.country"
            label="Country Code"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.phone"
            label="Phone"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.email"
            label="E-mail"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.postcode"
            label="Postcode"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.personalId"
            label="Personal ID"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-text-field
            v-model="paymentModel.customer.ip"
            label="IP"
            readonly
            variant="plain"
            persistent-placeholder
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-toast ref="vtoast"></v-toast>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOutline, mdiEyeOffOutline,
} from '@mdi/js'
import { ref, onMounted, computed } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import {
  formatCurrency, formatDateAndTime,
} from '@core/utils/filter'
import store from '@/store'
import storeModule from './storeModule'

import paymentMethodChannel from '@/constants/channel-code'

import RefundAddNew from '@/views/processing/payments/RefundAddNew.vue'
import VToast from '@/components/VToast.vue'

export default {
  components: { 
    RefundAddNew,
    VToast,
  },
  props: {
    id: {
      type: String,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'processing-payments'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    const vtoast = ref({})

    const paymentModel = ref({
      paymentMethod: {},
      customer: {},
      currencyExchangeRate: {},
      paymentCreditCardDetail: {},
    })

    const showCreditCardDetail = ref(false)
    const valid = ref(false)
    const form = ref(null)
    const paymentId = ref(props.id)


    // Refund
    const isAddNewRefundSidebarActive = ref(false)
    const paymentCode = ref('');
    const shopFilterItems = ref([])



    const validate = () => {
      form.value.validate()
    }

    const loading = ref(false)

    const amountDetail = computed(() => `${paymentModel.value.currencyCode} ${formatCurrency(paymentModel.value.amount)}`)
    const statusDetail = computed(() => `${paymentModel.value.paymentStatus?.statusCode} (${formatDateAndTime(paymentModel.value.paymentStatus?.statusUpdatedAt)})`)

    const merchantDiscountRateComputed = computed(() => {
      const mdr = {
        fixed: paymentModel.value.merchantDiscountRate?.fixed ? formatCurrency(paymentModel.value.merchantDiscountRate.fixed) : 0,
        percentage: paymentModel.value.merchantDiscountRate?.percentage ? `${formatCurrency(paymentModel.value.merchantDiscountRate.percentage)}%` : 0,
        totalDiscount: paymentModel.value.merchantDiscountRate?.totalDiscount ? formatCurrency(paymentModel.value.merchantDiscountRate.totalDiscount) : 0,
        rateDiscount: paymentModel.value.merchantDiscountRate?.rateDiscount ? formatCurrency(paymentModel.value.merchantDiscountRate.rateDiscount) : 0,
        minRateDiscount: paymentModel.value.merchantDiscountRate?.minRateDiscount ? formatCurrency(paymentModel.value.merchantDiscountRate.minRateDiscount) : 0,
        applyToCustomer: paymentModel.value.merchantDiscountRate?.applyToCustomer,
      }

      return mdr
    })

    // account settings data
    const fetch = () => {
      loading.value = true
      store.dispatch('processing-payments/fetch', {
        id: paymentId.value,
      })
        .then(response => {
          paymentModel.value = response.data
          showCreditCardDetail.value = paymentModel.value.paymentCreditCardDetail !== null
        })
        .catch(error => {
          vtoast.value.show(error.response.data.errors, 'error')
        })
        .finally(() => {
          loading.value = false
        })
    }


    // *===============================================---*
    // *--------- Refund ---------------------------------*
    // *===============================================---*

    const onRefundClick = ( ) => {
      paymentCode.value = paymentModel.value.paymentCode;
      isAddNewRefundSidebarActive.value = !isAddNewRefundSidebarActive.value
    }

    const onRefundSaved = message => {
      paymentModel.value.allowsToBeRefunded = false
      vtoast.value.show([{ message: `Your refund has been created successfully!`, code: '000' }], 'info')
    }

    const onRefundError = errors => {
      vtoast.value.show( errors.messages, errors.type )
    }


    onMounted(() => {
      fetch()
    })

    return {
      vtoast,
      fetch,
      amountDetail,
      merchantDiscountRateComputed,
      formatDateAndTime,
      formatCurrency,
      statusDetail,
      paymentId,
      paymentModel,
      showCreditCardDetail,
      loading,
      valid,
      form,
      validate,

      paymentMethodChannel,
      isAddNewRefundSidebarActive,
      paymentCode,
      shopFilterItems,
      onRefundClick,
      onRefundSaved,
      onRefundError,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: { required, emailValidator },
    }
  },
}
</script>
