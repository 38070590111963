var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-data-table',{ref:"notificationsTable",attrs:{"headers":_vm.notificationsTableColumns,"items":_vm.notificationsItems,"loading":_vm.loading,"disable-pagination":"","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.statusCode",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveStatusVariant(
            item.statusCode
          )) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.statusCode)}},[_vm._v(" "+_vm._s(item.statusCode)+" ")])]}},{key:"item.networkRequestBody",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"truncate",staticStyle:{"max-width":"330px"}},[_vm._v(" "+_vm._s(item.networkRequestBody)+" ")])]}},{key:"item.networkResponseBody",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"truncate",staticStyle:{"max-width":"330px"}},[_vm._v(" "+_vm._s(item.networkResponseBody)+" ")])]}},{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateAndTime(item.createdAt)))])]}}],null,true)})],1),_c('v-toast',{ref:"vtoast"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }